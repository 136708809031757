import React, { useMemo } from 'react'
import Layout from './components/Layout'
import Contact from './components/Contact'
import Seo from './components/Seo'
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up('xl')
  return createStyles({
    contact: {
      [xl]: {
        marginTop: '1rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

function ContactPage({ classes }: Props) {
  const contactClasses = useMemo(() => {
    return { root: classes.contact }
  }, [classes])

  return (
    <Layout>
      <Seo title="Contact" />
      <Contact classes={contactClasses} />
    </Layout>
  )
}

export default withStyles(styles)(ContactPage)
